












































































































import { Tooltip } from 'bootstrap'
import BsSupplierSelect from '../bs_supplier_select'
import BsDateSelect from '../bs_date_select'
import api from 'manager/services/base'

export default {
  components: { BsSupplierSelect, BsDateSelect }

  data: ->
    filter: {
      daterange: [],
      supplier_id: '',
      provider: null,
      provider_reference: ''
    },
    provider_options: [
      { label: 'MoyaPay', value: 'moya' },
      { label: 'PayGate', value: 'paygate' },
      { label: 'Shop2Shop', value: 'shop2shop' },
      { label: 'User credit', value: 'user_credit' },
      { label: 'YOCO', value: 'yoco' }
    ],
    pagination: {
      page: 1,
      pages: 1
    },
    exporting: false,
    fetching: false,
    collection: [],
    totals: {
      received: 'R 0.00',
      refunded: 'R 0.00',
      nett: 'R 0.00'
    }

  mounted: ->
    @bootstrap()
    @fetch()

  destroyed: ->
    tooltip.dispose() for tooltip in @tooltips
    @tooltips.splice(0, @tooltips.length)

  watch: {
    filter: {
      handler: -> @fetch()
      deep: true
    }
  }

  methods: {
    bootstrap: ->
      @tooltips = [ new Tooltip(@$refs.export) ]
      # tooltip.show() for tooltip in @tooltips

    on_supplier_select: (supplier) ->
      return @filter.supplier_id = '' unless supplier
      @filter.supplier_id = supplier.id

    fetch: ->
      @fetching = true
      api.payments.index(@filter_params())
      .then (response) =>
        @collection = response.payments
        @pagination.page = response.pagination.page
        @pagination.pages = response.pagination.pages
        @totals = response.totals
      .catch (error) => console.error("fetch_payments error; #{error}")
      .finally => @fetching = false

    filter_params: ->
      filter = JSON.parse(JSON.stringify(@filter))
      pagination = JSON.parse(JSON.stringify(@pagination))
      params = { from: filter.daterange[0] || '', until: filter.daterange[1] || ''}
      delete filter.daterange
      filter.provider = if filter.provider == null then '' else filter.provider
      Object.assign(params, filter, pagination)

    on_page: (page) ->
      @pagination.page = page
      @fetch()

    download: ->
      filter_params = @filter_params()
      api.download '/payments/export', filter_params
      .then (response) ->
        response.blob()
      .then (blob) =>
        url = window.URL.createObjectURL(blob)
        a = document.createElement('a')
        a.href = url
        filter = "#{filter_params.from}_#{filter_params.until}_#{filter_params.provider}_#{filter_params.provider_reference}"
        a.download = "payment_export_#{filter}.csv"
        document.body.appendChild(a)
        a.click()
        a.remove()
      .catch (error) =>
        console.error('Error fetching CSV:', error)
  }
}
