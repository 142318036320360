









































import { Modal } from 'bootstrap'
import { DateTime } from 'luxon'

export default {
  props: {
    placeholder_text: { type: String, default: 'Select a date' },
    style_class: { type: String, default: '' },
    value: { type: Array, default: -> [] }
  }

  data: -> {
    bs_modal: undefined,
    date_scope: 'date',
    daterange: '',
    formatted_output: ''
  }

  created: ->
    if @value.length == 1
      @date_scope = 'date'
      @daterange = @value[0]
    else if @value.length == 2
      @date_scope = 'daterange'
      @daterange = JSON.parse(JSON.stringify(@value))
    else
      @date_scope = 'date'
      @daterange = ''

  mounted: ->
    @bs_modal = new Modal(@$refs['date_modal'], {})

  beforeDestroy: ->
    @bs_modal.dispose()

  watch:
    daterange: (v) ->
      output = if @date_scope == 'date'
        [@daterange]
      else if @date_scope == 'month'
        @parse_dates_from_month(JSON.parse(JSON.stringify(@daterange)))
      else if @date_scope == 'daterange'
        @daterange
      @formatted_output = output.join(' - ')
      @$emit('input', output)

    date_scope: (v) ->
      @daterange = @build_defaults(v)

  methods:
    clear_daterange: ->
      @daterange = if @date_scope in ['date', 'month'] then '' else []
      @show_modal(false)

    build_defaults: (scope) ->
      if scope == 'date'
        DateTime.local().toISODate()
      else if scope == 'month'
        DateTime.local().toFormat('yyyy-MM')
      else if scope == 'daterange'
        [DateTime.local().toISODate(), DateTime.local().plus({days: 7}).toISODate()]

    show_modal: (really=true) ->
      if really then @bs_modal.show() else @bs_modal.hide()

    parse_dates_from_month: (month) ->
      [ year, month ] = month.split('-')
      return [] unless year and month

      [ DateTime.fromObject({ year: year, month: month, day: 1 }).toISODate(), DateTime.fromObject({ year: year, month: month, day: 1 }).endOf('month').toISODate() ]
}
