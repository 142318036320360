
import { Modal } from 'bootstrap'
import api from 'manager/services/base'
import Dropzone from 'dropzone'

export default {
  props: {
    browser_url: { type: String },
    assign_path: { type: String },
    selected: { type: String }
  },

  data: -> {
    dropzone: null
    images: []
    loading: false
    modal: null
    next_page: 1
    latest_selected: null
    query: ''
  }

  mounted: ->
    @modal = new Modal(@$refs['image-selector-modal'], {})
    @initialize_uploader()

  destroyed: ->
    @modal.dispose()

  methods: {
    show_selector: (event) ->
      @modal.show()

    hide_selector: (event) ->
      @modal.hide()

    initialize_uploader: ->
      @dropzone = new Dropzone @$refs['uploader'], {
        url: "#{api.api_endpoint()}#{@assign_path}",
        headers: { 'http-x-auth': @$store.state.user.id },
        acceptedFiles: '.jpg, .jpeg, .png',
        maxFiles: 1,
        accept: (file, done) ->
          if file.name.search(/(\\)|(\/)|(\,)/) > 0
            done('Filename may not contain slashes or commas')
          else
            done()
      }

      @dropzone.on 'complete', (file) =>
        response = JSON.parse(file.xhr.response)
        if response.status == 'error'
          alert('Image upload failed')
        else
          @emit_and_dismiss(response.resource.image_url)

      @dropzone.on 'sending', (file) =>
        @loading = true

      @dropzone.on 'queuecomplete', (progress) =>
        @loading = false

    on_query_type: (event) ->
      @fetch_query() if event.keyCode == 13

    fetch_query: ->
      @next_page = 1
      @images = []
      @paginate()

    paginate: ->
      api.images.search({ q: @query, next_page: @next_page, per_page: 9 })
      .then (response) =>
        @images = @images.concat(response.resources)
        @next_page = response.next_page

    on_scroll: (event) ->
      container = event.target
      if container.scrollTop + container.clientHeight >= container.scrollHeight
        @paginate()

    select: (image) ->
      url = api.api_endpoint() + @assign_path
      window.fetch url + "?image_id=#{image.id}", {
        method: 'POST'
        headers: api.authenticated_json(),
      }
      .then (response) -> response.json()
      .then (json) =>
        return @emit_and_dismiss(json.resource.image_url) if json.status == 'ok'
      .catch (error) -> console.error 'api.images.index:catch', error

    emit_and_dismiss: (image_url) ->
      @$emit('selected', image_url)
      @modal.hide()
  }
}
