






















export default {
  data: -> {
    options: {},
    daterange: [],
    daterange_menu: false
  }

  methods:
    clear_daterange: ->
      @daterange = []
      @daterange_menu = false

  props: ['value']

  created: ->
    @daterange = @value

  watch:
    daterange: ->
      @$emit('input', @daterange)
}
