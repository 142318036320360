import { render, staticRenderFns } from "./price_import.vue?vue&type=template&id=20fab666&scoped=true&"
import script from "./price_import.vue?vue&type=script&lang=coffee&"
export * from "./price_import.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20fab666",
  null
  
)

export default component.exports