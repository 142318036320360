import auth from '../../services/auth'

# initial state
state = () => ({
  id: null
  login: null
})

# getters
getters = {}

# actions
actions = {}

# mutations
mutations =
  set_user: (state, user) ->
    state.id = user?.id
    state.login = user?.login

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

store = ->

retreive = ->
