

























































import { mapState } from 'vuex'
import { DateTime } from 'luxon'

export default {
  data: -> {
    status_options: ['pending', 'preparing', 'stock_short', 'ready_for_collection', 'driver_dispatched', 'collected', 'delivered'],
    type_options: ['retail', 'stokvel', 'collection', 'delivery'],
    filter:
      suppliers: [],
      status: [],
      type: [],
      total_min: 0,
      total_max: 0,
      daterange: [],
      q: ''
    date_menu: false
  },

  computed: mapState {
    loading: (state) => state.orders.loading
    suppliers_options: -> @$store.getters['suppliers/as_select_options']
  }

  watch:
    filter:
      handler: ->
        filter_params = JSON.parse(JSON.stringify @filter)
        filter_params.daterange.sort (a,b) -> if DateTime.fromISO(a).toString() < DateTime.fromISO(b).toString() then -1 else 1
        [ filter_params.from, filter_params.to ] = filter_params.daterange
        delete filter_params.daterange
        @$store.dispatch 'orders/set_filter', filter_params
      deep: true

  methods: {
    on_filter: ->
      @$store.dispatch 'orders/fetch'

    clear_date_filter: ->
      @filter.daterange = []
      @date_menu = false
  }
}
