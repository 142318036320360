


































































































































































































import api from 'manager/services/base'
import Dropzone from 'dropzone'
import BsSupplierSelect from 'manager/components/bs_supplier_select.vue'

export default {
  components: { BsSupplierSelect }

  data: ->
    {
      form: @form_defaults(),
      form_show: false,
      errors: {},
      form_loading: false,
      leaflets: [],
      leaflets_loading: false,
      updating: false
      supplier_select_key: true
      pagination: {
        page: 1
        pages: 1
        per_page: 10
      }
  }

  mounted: ->
    @fetch_leaflets()
    @initialize_uploader()

  watch: {
    "form.name": (new_value, old_value) -> @errors.name = []
    "form.path": (new_value, old_value) -> @errors.path = []
    "form.start_at": (new_value, old_value) -> @errors.start_at = []
    "form.end_at": (new_value, old_value) -> @errors.end_at = []
    "form.visible_at": (new_value, old_value) -> @errors.visible_at = []
  }

  methods: {
    initialize_uploader: ->
      @dropzone = new Dropzone @$refs['uploader'], {
        url: "#{api.api_endpoint()}/leaflets/upload",
        headers: { 'http-x-auth': @$store.state.user.id },
        acceptedFiles: '.pdf',
        maxFiles: 1,
        addRemoveLinks: true,
        accept: (file, done) ->
          if file.name.search(/(\\)|(\/)|(\,)/) > 0
            done('Filename may not contain slashes or commas')
          else
            done()
      }

      @dropzone.on 'complete', (file) =>
        response = JSON.parse(file.xhr.response)
        if response.status == 'error'
          alert('PDF upload failed')
        else
          @form.path = response.path

      @dropzone.on 'sending', (file) =>
        @loading = true

      @dropzone.on 'queuecomplete', (progress) =>
        @loading = false

    form_toggle: (show) ->
      @form_show = show

    has_errors: (property) ->
      property && property.length > 0

    submit_form: (event) ->
      event.preventDefault()
      @form_loading = true
      api.leaflets.create({ leaflet: @form })
      .then (response) => @create_leaflet_success(response)
      .catch (error) => @create_leaflet_error(error)
      .finally => @form_loading = false

    create_leaflet_success: (response) ->
      if response.status == 'error'
        @errors = response.errors
      else
        @dropzone.removeAllFiles()
        @fetch_leaflets()
        @reset_form()

    form_defaults: ->
      {
        name: "",
        path: ""
        published: false,
        start_at: "",
        end_at: "",
        visible_at: "",
        supplier_ids: [],
      }

    reset_form: ->
      @form = @form_defaults()
      @errors = {}
      @supplier_select_key = !@supplier_select_key

    create_leaflet_error: (error) ->
      console.error("create_leaflet_error; #{error}")

    on_supplier_select: (suppliers) ->
      @form.supplier_ids = suppliers.map (supplier) -> supplier.id
      @errors.suppliers = []

    on_page: (page) ->
      @pagination.page = page
      @fetch_leaflets()

    fetch_leaflets: ->
      @loading_leaflets = true
      api.leaflets.index({page: @pagination.page, per_page: @pagination.per_page})
      .then (response) =>
        @leaflets = response.leaflets
        @pagination.page = response.pagination.page
        @pagination.pages = response.pagination.pages
        @pagination.count = response.pagination.count
      .catch (error) => console.error("fetch_leaflets error; #{error}")
      .finally => @loading_leaflets = false

    publish: (leaflet) ->
      @updating = true
      api.leaflets.update(leaflet.id, { leaflet: { published: !leaflet.published } })
      .then (response) =>
        leaflet = @leaflets.find((l) => l.id == response.id)
        leaflet.published = response.published
      .catch (error) => console.error("publish error; #{error}")
      .finally => @updating = false
  }
};
