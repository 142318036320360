<template>
  <div class="container pa-6">
    <h1 class="mb-6">Discount Codes</h1>

    <div class='row'>
      <div class='col-12 col-md-4'>
        <div class="card mb-4">
          <div class="card-header d-flex align-items-center">
            <h3 class="mb-0">New Discount Code</h3>
<!--             <button @click='form_show = !form_show' class='btn btn-primary ml-auto' >
              {{form_show ? "&minus;" : "&plus;"}}
            </button> -->
          </div>
          <div v-show="form_show" class="card-body">
            <form>
              <div class='form-row'>
                <div class='form-group col'>
                  <label for='discount_code'>Code</label>
                  <input ref='code_input' v-model='form.code' id='discount_code' type='text' class='form-control' :class='form_errors.code && "is-invalid"'/>
                  <div v-if="form_errors.code && form_errors.code.length > 0" class="invalid-feedback">
                    {{ form_errors.code.join(", ") }}
                  </div>
                </div>
              </div>
              <div class='form-row'>
                <div class='form-group col'>
                  <label for='value'>Value</label>
                  <input v-model='form.value' id='value' type='number' class='form-control' min='1' :max='form.value_type == "flat" ? 999999 : 100' step='1' :class='form_errors.value && "is-invalid"'/>
                  <div v-if="form_errors.value && form_errors.value.length > 0" class="invalid-feedback">
                    {{ form_errors.value.join(", ") }}
                  </div>
                </div>
                <div class='form-group col'>
                  <label for='value_type'>Type</label>
                  <select v-model='form.value_type' id='value_type' class='form-control' :class='form_errors.value_type && "is-invalid"'>
                    <option value='flat'>{{currency_iso}}</option>
                    <option value='percent'>%</option>
                  </select>
                  <div v-if="form_errors.value_type && form_errors.value_type.length > 0" class="invalid-feedback">
                    {{ form_errors.value_type.join(", ") }}
                  </div>
                </div>
              </div>
              <button type='submit' class='btn btn-success text-white btn-block' @click='submit_form'>Save Discount Code</button>
            </form>
          </div>
        </div>
      </div>

      <div class='col'>
        <div class="card mb-4">
          <table class="table mb-0">
            <thead class="margin-top: 0;">
              <tr>
                <th style="border-top: 0px; !important">Code</th>
                <th style="border-top: 0px; !important">Discount</th>
                <th style="border-top: 0px; !important" class='text-center'>Published?</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="resource in discount_codes" :key="resource.id">
                <td>{{ resource.code }}</td>
                <td class='text-right'>{{ resource.formatted }}</td>
                <td class='text-center'>
                  <div class="custom-control custom-checkbox">
                    <input
                      :disabled="updating"
                      :id="`enable-${resource.id}`"
                      type="checkbox"
                      class="custom-control-input"
                      :checked="resource.enabled"
                      @change="enable(resource)"
                    />
                    <label
                      :for="`enable-${resource.id}`"
                      class="custom-control-label"
                    >
                      Published
                    </label>
                  </div>
                </td>
                <td clas='text-center'>
                  <button type='button' class='btn btn-sm btn-danger text-light' @click='destroy(resource.id)'>Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <iframe ref='iframe' src='http://api.localhost:3000/manager/api/v1/discount_codes.html' class='w-100'/> -->

  </div>
</template>

<script>
import api from 'manager/services/base'
import app_config from '../../application.yml'

export default {
  data() {
    return {
      form_show: true,
      form: this.form_defaults(),
      form_errors: {},
      discount_codes: [],
      fetching: false,
      updating: false,
      currency_iso: app_config.meta.currency_iso,
      pagination: {
        count: 0,
        page: 1,
        pages: 1,
        per_page: 20
      }
    }
  },

  mounted: function() {
    this.fetch()
  },

  methods: {
    on_page(page) {
      this.pagination.page = page
      this.fetch_leaflets()      
    },

    fetch() {
      this.fetching = true
      api.discount_codes.index({page: this.pagination.page, per_page: this.pagination.per_page})
      .then((response) => {
        this.discount_codes = response.discount_codes
        this.pagination.page = response.pagination?.page
        this.pagination.pages = response.pagination?.pages
        this.pagination.count = response.pagination?.count        
      })
      .catch((error) => console.error(error))
      .finally(() => {this.fetching = false})
    },

    enable(discount_code) {
      this.updating = true
      api.discount_codes.update(discount_code.id, { discount_code: { enabled: !discount_code.enabled } })
      .then((response) => {
        discount_code = this.discount_codes.find((code) => code.id === response.id)
        discount_code.enabled = response.enabled
      })
      .catch((error) => console.error(error))
      .finally(() => this.updating = false)
    },

    destroy(id) {
      this.updating = true
      api.discount_codes.destroy(id)
      .then((response) => {
        let index = this.discount_codes.findIndex((code) => code.id === response.id)
        this.discount_codes.splice(index, 1)
      })
      .catch((error) => console.error(error))
      .finally(() => this.updating = false)
    },    

    submit_form(event) {
      event.preventDefault()
      this.form_loading = true
      let form = JSON.parse(JSON.stringify(this.form))
      form.value = form.value_type == 'flat' ? form.value * 100 : form.value
      api.discount_codes.create({discount_code: form})
      .then((response) => this.create_success(response))
      .catch((errors) => this.create_errors(errors))
      .finally(() => this.form_loading = false)      
    },

    create_success(response) {
      if (response.status == 'error') {
        this.form_errors = response.errors
      }
      else {
        this.fetch()
        this.reset_form()        
      }
    },

    create_errors: (errors) => {
      this.form_errors = errors
    },

    form_defaults() {
      return {
        code: 'defaultcode',
        value: 10,
        value_type: 'flat'
      }
    },

    reset_form() {
      this.form = this.form_defaults()
      this.form_errors = {}
      console.log('reset_form', this.refs['code_input'])
      this.refs['code_input'].focus()
    }
  }
}
</script>
