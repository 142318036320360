

































import { Info } from 'luxon'
import SelectTime from '../../select_time'
import { capitalize, keys } from 'lodash'

export default {

  components: { SelectTime }

  data: -> {
    days: Info.weekdays()
    form: {
      id: null
      hours: ({ weekday: day, open: '08:00', close: '18:00' } for day in [1..7])
    }
    submitting: false
  }

  computed: {
    supplier: ->
      @$store.state.suppliers.current
  }

  methods: {
    on_submit: (event) ->
      event.preventDefault()
      @submitting = true
      @$store.dispatch 'suppliers/update', JSON.parse(JSON.stringify(@form))
      .finally =>
        @submitting = false

    capitalize: (string) -> capitalize(string)

    prepopulate_form: ->
      supplier = JSON.parse(JSON.stringify(@supplier))
      @form = @defaults()
      @form.id = supplier.id
      @form.hours = supplier.hours if @valid_hours_array(supplier.hours)

    valid_hours_array: (hours) ->
      Array.isArray(hours) and
      hours[0]?.hasOwnProperty('weekday') ||
      hours[0]?.hasOwnProperty('open') ||
      hours[0]?.hasOwnProperty('close')

    defaults: ->
      {
        id: null
        hours: ({ weekday: day, open: '08:00', close: '18:00' } for day in [1..7])
      }
  }

  watch: {
    supplier: (supplier) ->
      @prepopulate_form()
  }

  created: ->
    @prepopulate_form()
}
