import api from '../../services/base'
import { omit } from 'lodash'

state = -> {
  loading: true
  all: []
  current: null
}

getters = {
  all: (state) -> state.all.map (s) -> { id: s.id, text: s.name }
  as_select_options: (state) -> state.all.map (s) -> { value: s.id, text: s.name }
}

actions = {
  fetch: ({ commit }) ->
    api.fetch('/suppliers')
    .then (suppliers) ->
      commit('set_suppliers', suppliers)
      suppliers
    .catch (error) ->
      console.error 'suppliers.catch', error

  fetch_one: ({ commit }, supplier_id) ->
    api.fetch("/suppliers/#{supplier_id}")
    .then (supplier) ->
      commit('set_supplier', supplier)
    .catch (error) ->
      console.error 'suppliers.fetch_one', error

  update: ({ commit }, supplier) ->
    api.update_supplier supplier.id, omit(supplier, ['id'])
    .then (response) ->
      commit('set_supplier', response)
    .catch (error) ->
      console.error error
}

mutations = {
  loading: (state, loading) ->
    state.loading = loading

  set_suppliers: (state, suppliers) ->
    state.all = JSON.parse(JSON.stringify(suppliers))

  set_supplier: (state, supplier) ->
    state.current = JSON.parse(JSON.stringify(supplier))
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
