













import auth from '../../services/auth'

export default {
  data: -> {
    login: if process.env.NODE_ENV == 'development' then 'manager@foo.bar' else '',
    password: if process.env.NODE_ENV == 'development' then 'password' else '',
    message: ''
    loading: false
  },

  methods: {
    sign_in: ->
      @loading = true
      auth.sign_in { login: @login, password: @password}, (response) =>
        if response.status == 'error'
          @message = response.message
          @loading = false
        else
          @$store.commit 'user/set_user', response.manager
          @$router.replace @$route.query.redirect || '/'
      .catch (error) =>
        @message = error
        @loading = false
  }
}
