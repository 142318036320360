



















export default {
  props: {
    title: { default: 'Rankings' },
    icon: { default: 'mdi-star' },
    items: { default: [{ id: 'uuid', text: 'name', score: '0' }] }
  }
}
