
import { Doughnut, mixins } from 'vue-chartjs'
{ reactiveProp } = mixins

export default {
  extends: Doughnut
  mixins: [reactiveProp]
  props: ['options']

  methods:
    update: ->
      @$data._chart.update()

  mounted: ->
    @renderChart(@chartData, @options)
    @htmlLegend = ''
}
