


































import auth from './services/auth'

export default {
  data: -> {}

  created: ->
    @$store.commit 'user/set_user', auth.logged_in()

  computed:
    logged_in: -> @$store.state.user.id
    user_login: -> @$store.state.user.login
    is_auth_page: -> @$router.currentRoute.name == 'auth'

}
