













import api_config from '../services/base'

export default {
  props: ['id']
  data: ->
    name: '',
    phone: ''
    loading: true

  methods:
    fetch_user: ->
      @loading = true
      fetch "#{api_config.api_endpoint()}/users/#{@id}", api_config.build_options()
      .then (response) -> response.json()
      .then (json) => [ @phone, @name, @loading ] = [ json.login, json.full_name, false ]
      .catch (error) -> console.error 'fetch_user caught:', error

  mounted: ->
    @fetch_user()
}
