




















































































































































import { mapState } from 'vuex'
import { DateTime } from 'luxon'
import user_info from '../user_info'
import order_jobs from '../order_jobs'

export default {
  components:
    'user-info': user_info
    'order-jobs': order_jobs

  data: ->
    order_headers: [
      '',
      'Barcode',
      'Name',
      'Unit price',
      'Qty',
      'Total'
    ],
    order: null

  computed: {
    show_removed_order_items_section: ->
      @order &&
      @order.removed_order_items &&
      @order.removed_order_items.length > 0
  }

  created: ->
    @$store.dispatch 'order/fetch', @$route.params
    .then (order) =>
      @order = order
      @begin_poll()
    .catch (error) -> console.error 'order/fetch caught:', error

  beforeDestroy: ->
    @$store.commit 'order/polling', false

  methods:
    formatted_time: (time) ->
      new DateTime.fromISO(time).toFormat('ff')

    number_to_currency: (int) ->
      "R #{(Number(int) / 100).toFixed(2)}"

    formatted_text: (string) ->
      text = String(string).split('_').join(' ')
      text[0].toUpperCase() + text.slice(1).toLowerCase()

    item_is_stock_short: (qty, qty_ordered) ->
      qty < qty_ordered

    begin_poll: ->
      @$store.commit 'order/polling', true
      @$store.dispatch 'order/fetch', @$route.params
}
