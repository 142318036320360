import orders from '../../services/orders'

state = ->
  order_items: []

actions =
  set: ({ commit }, attributes) ->
    commit 'set', attributes

  clear: ({ commit }) ->
    commit 'destroy'

  fetch: ({ commit, dispatch, state }, params) ->
    commit 'loading', true
    orders.fetch_order params, (response) =>
      if response.status == 'ok'
        commit 'set', response.order
        commit 'loading', false
        commit('timeout_id', clearTimeout state.timeout_id)
        if state.polling then dispatch('poll')
        response.order

  poll: ({ commit, dispatch, state }) ->
    commit 'timeout_id', setTimeout =>
      dispatch 'fetch', { id: state.id }
    , 30000

mutations =
  destroy: (state) ->
    state = (value = null for key, value of state)
    destroy_local()

  loading: (state, loading) ->
    state.loading = loading

  restore: (state) ->
    state = Object.assign state, { get_local()... }

  polling: (state, polling) ->
    state.polling = polling

  set: (state, order) ->
    state = Object.assign state, order
    set_local(state)

  timeout_id: (state, timeout_id) ->
    state.timeout_id = timeout_id


export default {
  namespaced: true
  state,
  actions,
  mutations
}

get_local = -> JSON.parse localStorage.getItem('order')

set_local = (data) -> localStorage.setItem 'order', JSON.stringify(data)

destroy_local = -> localStorage.removeItem 'order'
