



















































































































































































































































































































































import DaterangeSelect from '../daterange_select'
import DoughnutChart from '../charts/base/doughnut'
import randomColor from 'randomcolor'
import OrderStatusChart from '../charts/order_status_chart'
import Rankings from '../rankings'
import _ from 'underscore'
import { DateTime } from 'luxon'
import { number_to_currency } from '../../tools'
import currency from 'currency.js'

export default {
  components: { DaterangeSelect, DoughnutChart, OrderStatusChart, Rankings }

  data: ->
    {
      filter_form: {
        suppliers: [],
        daterange: []
      }
      options: { legend: { display: true, position: 'bottom' }},
      user_activity_options: { legend: { display: true, position: 'bottom' }},
      rating_colors: { 5: 'green', 4: 'lime', 3: 'yellow', 2: 'orange', 1: 'red' }
    }

  created: ->
    @$store.dispatch('suppliers/fetch')

  computed:
    filter: ->
      filter = JSON.parse(JSON.stringify(@filter_form))
      filter.daterange.sort (a,b) -> if DateTime.fromISO(a).toString() < DateTime.fromISO(b).toString() then -1 else 1
      filter.begin_on = filter.daterange[0] if !!filter.daterange[0]
      filter.end_on = filter.daterange[1] if !!filter.daterange[1]
      delete filter.daterange
      filter

    orders_complete_count: ->
      @$store.state.kpis.orders_by_status?.total || 0

    orders_total_qty: ->
      @$store.state.kpis.orders_total_qty

    orders_total_value: ->
      @number_to_currency(@$store.state.kpis.orders_total_revenue || 0)

    reviews_average: ->
      @$store.state.kpis.reviews_average

    orders_by_total: -> @$store.state.kpis.orders_by_total

    users_total_value: ->
      @$store.state.kpis.users_total_value

    users_count_by_orders: ->
      @$store.state.kpis.users_count_by_orders

    users_count_by_orders_labels: ->
      {
        'single_order': '1 order',
        'multiple_orders': '> 1 order',
        'no_orders': '0 orders'
      }

    users_count_by_orders_chart: ->
      chart_data = _.omit(@$store.state.kpis.users_count_by_orders, 'total')
      labels = (@users_count_by_orders_labels[item] for item in _.keys(chart_data))
      color_legend = {multiple_orders: 'green', single_order: 'lime', no_orders: 'grey'}
      {
        labels: labels
        datasets: [
          backgroundColor: (color_legend[key] for key in _.keys(chart_data))
          data: _.values(chart_data)
        ]
      }

    user_retention_percent: ->
      @$store.state.kpis.user_retention_percent

    barcode_activity: ->
      @$store.state.kpis.sku_count_by_activity

    barcode_activity_labels: ->
      {
        sold: 'Sold',
        not_sold: 'Not sold'
      }

    barcode_activity_chart: ->
      chart_data = _.omit(@$store.state.kpis.sku_count_by_activity, 'total')
      color_legend = { sold: 'green', not_sold: 'grey' }
      labels = (@barcode_activity_labels[item] for item in _.keys(chart_data))
      {
        labels: labels
        datasets: [
          backgroundColor: (color_legend[key] for key in _.keys(chart_data))
          data: _.values(chart_data)
        ]
      }

    supplier_options: ->
      @$store.getters['suppliers/all']

    supplier_customers_ranking: ->
      suppliers = _.sortBy(@$store.state.kpis.suppliers_by_rating, 'average_rating').map (supplier, i) ->
        { id: supplier.id, text: supplier.name, score: supplier.average_rating }
      suppliers.reverse()

    supplier_orders_preparation_ranking: ->
      @supplier_options.map (supplier, i) ->
        { id: supplier.id, text: supplier.text, score: (i + 1) * 60 + ' min' }

    supplier_orders_count_ranking: ->
      suppliers = JSON.parse(JSON.stringify(@$store.state.kpis.suppliers_by_orders_count)) || []
      suppliers = _.sortBy(suppliers, 'count').map (supplier, i) ->
        { id: supplier.id, text: supplier.name, score: supplier.count }
      suppliers.reverse()

    supplier_orders_value_ranking: ->
      suppliers = JSON.parse(JSON.stringify(@$store.state.kpis.suppliers_by_orders_count)) || []
      suppliers = _.sortBy(suppliers, 'value').map (supplier, i) =>
        { id: supplier.id, text: supplier.name, score: @number_to_currency(supplier.value) }
      suppliers.reverse()

    packer_ratings: ->
      [
        { id: '', name: 'Philippi_packer_2', score: 50 },
        { id: '', name: 'Makhaza_packer_1', score: 45 },
        { id: '', name: 'Mfuleni_packer_3', score: 40 },
        { id: '', name: 'Delft_packer_1', score: 30 }
      ]

    cashier_ratings: ->
      [
        { id: '', name: 'Philippi_cashier_2', score: 50 },
        { id: '', name: 'Makhaza_cashier_1', score: 45 },
        { id: '', name: 'Mfuleni_cashier_3', score: 40 },
        { id: '', name: 'Delft_cashier_1', score: 30 }
      ]

  methods:
    rating_color: (rating) ->
      @rating_colors[rating]

    apply_selection: ->
      query = new URLSearchParams(@filter)
      window.location.hash = query.toString()
      @$store.dispatch('kpis/refresh', @filter)

    number_to_currency: (int) ->
      currency(int, { fromCents: true }).format({ symbol: 'R ' })

  mounted: ->
    query_string = window.location.hash.replace(/^\#/, '')
    filter = new URLSearchParams(query_string)
    if filter.get('suppliers')
      ids = filter.get('suppliers').split(',')
      if ids.length > 0
        @filter_form.suppliers = ids.map (s) -> { name: '', id: s }
    if filter.has('begin_on') || filter.has('end_on')
      @filter_form.daterange.push(filter.get('begin_on')) if filter.has('begin_on')
      @filter_form.daterange.push(filter.get('end_on')) if filter.has('end_on')
    else
      today = new DateTime.local()
      @filter_form.daterange.push(today.minus({ months: 1 }).toISODate())
      @filter_form.daterange.push(today.toISODate())
    @$store.dispatch('kpis/refresh', @filter)
}
