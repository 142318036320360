























































import DoughnutChart from './base/doughnut'

export default {
  props: ['options']

  components: { DoughnutChart }

  computed: {
    completed_order_count: ->
      @$store.state.kpis.orders_by_status?.complete || 0

    collected_order_count: ->
      @$store.state.kpis.orders_by_status?.collected || 0

    delivered_order_count: ->
      @$store.state.kpis.orders_by_status?.delivered || 0

    cancelled_orders: ->
      @$store.state.kpis.orders_by_status?.cancelled

    abandoned_orders: ->
      @$store.state.kpis.orders_by_status?.abandoned

    failed_orders: ->
      @$store.state.kpis.orders_by_status?.failed

    preparing_orders: ->
      @$store.state.kpis.orders_by_status?.preparing

    chart_data: ->
      {
        labels: ['collected','delivered','cancelled','abandoned','preparing']
        datasets: [{
          backgroundColor: ['green', 'green', 'grey', 'grey', 'orange'],
          data: [
            @collected_order_count,
            @delivered_order_count,
            @cancelled_orders,
            @abandoned_orders,
            @preparing_orders,
          ]
        }]
      }

    chart_options: ->
      Object.assign(JSON.parse(JSON.stringify(@options)), {
        legend: { position: 'bottom', boxWidth: 20 }
      })
  }
}
