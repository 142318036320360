






import flatpickr from 'flatpickr'
require("flatpickr/dist/flatpickr.css")

export default {

  props: {
    min: null,
    max: null,
    value: null
  }

  data: -> {
    time: ''
  }

  mounted: ->
    options = {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      minuteIncrement: 5,
      static: true
    }
    options.minDate = @min if @min
    options.maxDate = @max if @max
    options.defaultDate = @value
    @timepicker = flatpickr(@$refs['input'], options)

  destroyed: ->
    @timepicker.destroy()

  watch: {
    time: (v) ->
      @$emit('input', v)

    min: (v) ->
      @timepicker.set 'minDate', v

    max: (v) ->
      @timepicker.set 'maxDate', v

    value: (v) ->
      @timepicker.setDate(v)
  }

}
