import base from '../../services/base'
import randomColor from 'randomcolor'

defaults = ->
  {
    loading: true
    orders_by_status: null
    orders_total_revenue: null
    orders_total_qty: {
      unit_qty: 0,
      per_kg_qty: 0
    }
    orders_by_total: {
      all: { avg: 0, max: 0, min: 0 },
      collected: { avg: 0, max: 0, min: 0 },
      delivered: { avg: 0, max: 0, min: 0 }
    },
    sku_count_by_activity: {
      sold: 0,
      not_sold: 0,
      total: 0,
      not_visible: 0
    },
    suppliers_by_orders_count: [],
    suppliers_by_rating: []
    users_total_value: { avg: 0, max: 0, min: 0 }
    users_count_by_orders: { total: 0, multiple_orders: 0, one_order: 0, no_orders: 0}
    user_retention_percent: null
    reviews_average: {
      average_rating: 0,
      by_rating: [],
      total: 0
    },
    querystring: ''
  }

state = defaults()

getters = {
  orders_by_status: (state) -> JSON.parse(JSON.stringify(state.orders_by_status))
  query: (state) -> if state.querystring.length > 0 then "?#{state.querystring}" else ''
}

actions = {
  fetch_orders_by_status: ({ commit, getters }) ->
    base.fetch("/kpis/orders_count_by_status#{getters.query}")
    .then (response) ->
      commit('orders_by_status', response)
    .catch (error) ->

  fetch_orders_total_revenue: ({ commit, getters }) ->
    base.fetch("/kpis/orders_total_revenue#{getters.query}")
    .then (response) ->
      commit('orders_total_revenue', response)
    .catch (error) ->

  fetch_kpi: ({ commit, getters }, kpi) ->
    base.fetch("/kpis/#{kpi}#{getters.query}")
    .then (response) ->
      commit(kpi, response) unless response == undefined
    .catch (error) -> console.error 'fetch_kpi:error', kpi, error

  refresh: ({ commit, dispatch }, filter) ->
    commit('querystring', new URLSearchParams(filter).toString())
    dispatch('fetch_orders_by_status')
    dispatch('fetch_orders_total_revenue')
    dispatch('fetch_kpi', 'reviews_average')
    dispatch('fetch_kpi', 'orders_total_qty')
    dispatch('fetch_kpi', 'orders_by_total')
    dispatch('fetch_kpi', 'users_total_value')
    dispatch('fetch_kpi', 'users_count_by_orders')
    dispatch('fetch_kpi', 'user_retention_percent')
    dispatch('fetch_kpi', 'suppliers_by_orders_count')
    dispatch('fetch_kpi', 'suppliers_by_rating')
    dispatch('fetch_kpi', 'sku_count_by_activity')

# orders_total_qty
# orders_count
# reviews_average
# orders_mam_by_status
# orders_by_preparation_time
# users_total_value
# users_count_by_orders
# sku_count_by_activity
# suppliers_by_rating
# suppliers_by_preparation_time
# suppliers_by_orders_count
# suppliers_by_orders_value
# packers_by_preparation_time
# scanners_by_preparation_time
}

mutations = {
  loading: (state, loading) ->
    state.loading = loading

  querystring: (state, q) ->
    state.querystring = q

  orders_by_status: (state, data) ->
    state.orders_by_status = data

  orders_total_revenue: (state, data) ->
    state.orders_total_revenue = data

  reviews_average: (state, data) ->
    state.reviews_average = data

  orders_total_qty: (state, data) ->
    state.orders_total_qty = data

  orders_by_total: (state, data) ->
    state.orders_by_total = data

  users_total_value: (state, data) ->
    state.users_total_value = data

  users_count_by_orders: (state, data) ->
    state.users_count_by_orders = data

  user_retention_percent: (state, data) ->
    state.user_retention_percent = data

  suppliers_by_orders_count: (state, data) ->
    state.suppliers_by_orders_count = data

  suppliers_by_rating: (state, data) ->
    state.suppliers_by_rating = data

  sku_count_by_activity: (state, data) ->
    state.sku_count_by_activity = data
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
