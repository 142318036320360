






export default {
  data: -> {
    message: "404: page not found"
  }
}
