import Vue from 'vue'
import Vuex from 'vuex'
import orders from './modules/orders'
import order from './modules/order'
import user from './modules/user'
import kpis from './modules/kpis'
import suppliers from './modules/suppliers'

Vue.use(Vuex)

debug = process.env.NODE_ENV != 'production'

export default new Vuex.Store {
  modules: {
    kpis,
    orders,
    order,
    suppliers,
    user
  },
  strict: debug,
  # plugins: if debug then [createLogger()] else []
}
