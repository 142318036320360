import orders from '../../services/orders'

# initial state
state = =>
  all: [],
  filters: {},
  pagination: {
    itemsPerPage: 20,
    page: 1,
    sortDesc: true,
    sortBy: ''
  },
  query: '',
  orders_count: 0,
  loading: true,
  timeout_id: null,
  polling: false
  updated_at: ''
  pending_carter_count: 0

# getters
getters =
  build_params: (state, getters) =>
    pagination = getters.pagination_params
    filters = getters.filter_params
    Object.assign pagination, filters

  pagination_params: (state) =>
    data = JSON.parse JSON.stringify(state.pagination)
    {
      per_page: data.itemsPerPage,
      page: data.page,
      desc: data.sortDesc,
      sort_by: data.sortBy
    }

  filter_params: (state) =>
    JSON.parse JSON.stringify(state.filters)

# actions
actions =
  fetch: ({ commit, getters, dispatch, state }) ->
    params = getters.build_params
    commit 'loading', true
    orders.fetch_orders params, (response) =>
      commit 'set_orders_count', response.pagination?.count
      commit 'set_orders', response.orders
      commit 'updated_at', new Date().toISOString()
      commit 'loading', false
      commit 'timeout_id', clearTimeout state.timeout_id
      commit 'pending_carter_count', response.pending_carter_count
      if state.polling then dispatch('poll')

  set_filter: ({ commit, state }, params={}) ->
    commit 'set_pagination', Object.assign JSON.parse(JSON.stringify state.pagination), { page: 1 }
    commit 'set_filter', params

  set_query: ({ commit, dispatch }, query) ->
    commit 'query', query
    dispatch 'fetch'

  update_pagination: ({ commit, dispatch }, params={}) ->
    commit 'set_pagination', params
    dispatch 'fetch'

  poll: ({ commit, dispatch }) ->
    commit 'timeout_id', setTimeout =>
      dispatch 'fetch'
    , 60000

# mutations
mutations =
  set_orders: (state, orders) ->
    state.all = orders

  set_orders_count: (state, orders_count) ->
    state.orders_count = orders_count

  set_pagination: (state, pagination) ->
    state.pagination = pagination

  set_filter: (state, filter) ->
    state.filters = filter

  query: (state, query) ->
    state.query = query

  loading: (state, loading) ->
    state.loading = loading

  polling: (state, polling) ->
    state.polling = polling

  timeout_id: (state, timeout_id) ->
    state.timeout_id = timeout_id

  updated_at: (state, time) ->
    state.updated_at = time

  pending_carter_count: (state, count) ->
    state.pending_carter_count = count

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
