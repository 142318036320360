















export default {
  props: ['multiple', 'custom_class']

  data: -> {
    options: [],
    selections: []
  }

  created: ->
    @$store.dispatch('suppliers/fetch')
    .then (suppliers) =>
      @options = suppliers

  watch: {
    selections: (new_value, old_value) ->
      @$emit("change", new_value)
    }
}
