





























import { Dropzone } from 'dropzone'
import base_service from '../../services/base'

export default {
  name: '',
  data: -> {
    header: 'Price upload',
    columns: [ 'created at', 'branch', 'type', 'status'],
    price_imports: [],
    upload_error: null,
    dropzone: null,
    poll_id: 0
  },
  props: [],

  created: ->
    @fetch_imports()
    @poll_imports()

  methods: {
    download_url: (id) ->
      "#{ base_service.api_endpoint() }/api/imports/#{ id }/download"

    fetch_imports: (options={}) ->
      fetch "#{ base_service.api_endpoint() }/imports", { headers: { 'http-x-auth': @$store.state.user.id } }
      .then (response) -> response.json()
      .then (imports) => @price_imports = imports
      .finally =>
        return if options.poll == undefined
        @poll_imports()

    poll_imports: ->
      @poll_id = setTimeout =>
        @fetch_imports({ poll: true })
      , 3000

    import_status: (price_import) ->
      if price_import.error_message.length > 0 then price_import.error_message else price_import.status

    status_class: (status) ->
      switch status
        when 'importing', 'publishing' then 'yellow'
        when 'completed' then 'green'
        when 'failed' then 'red'
  }

  mounted: ->
    @dropzone = new Dropzone @$refs['price_import_upload'], {
      url: "#{base_service.api_endpoint()}/imports",
      headers: { 'http-x-auth': @$store.state.user.id }
    }

    @dropzone.on 'complete', (file) =>
      response = JSON.parse(file.xhr.response)
      if response.status == 'error'
        @upload_error = "#{ file.name }:\n#{ response.message }"
      else @fetch_imports()

    @dropzone.on 'addedfile', =>
      @upload_error = null

  watch: {}

  destroyed: ->
    clearTimeout(@poll_id)
    @poll_id = 0
}
