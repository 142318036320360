



















import api_config from '../services/base'
import { DateTime } from 'luxon'
export default {
  props: ['id']

  data: ->
    jobs: []
    loading: false

  methods:
    fetch_jobs: ->
      @loading = true
      fetch "#{api_config.api_endpoint()}/orders/#{@id}/jobs", api_config.build_options()
      .then (response) -> response.json()
      .then (json) =>
        # console.log '[order_jobs.methods].fetch_jobs', @jobs, json.jobs
        @jobs.push(job) for job in json.jobs
        @loading = false
      .catch (error) -> console.error 'fetch_jobs caught:', error

    build_job_label: (job_type) ->
      switch job_type
        when 'supplier_order_pack' then 'Picker'
        when 'supplier_order_process' then 'Cashier'

    formatted_time: (time) ->
      new DateTime.fromISO(time).toFormat('ff')

    formatted_text: (string) ->
      String(string).split('_').join(' ')

  created: ->
    @fetch_jobs()
}
