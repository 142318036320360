











































































































































































import api from 'manager/services/base'
import { Modal } from 'bootstrap'

export default {
  data: -> {
    sellables: []
    loading_sellables: false
    loading_sellable: []
    sellable: null
    offers: []
    loading_offers: false
    pagination: {
      page: 1
      pages: 1
      per_page: 10
    }
    filter: {
      barcode: null
      name: null
      published: false
    }
  }

  created: ->
    @fetch_sellables()

  mounted: ->
    @offers_modal = new Modal(@$refs['offers_modal'], {})

  unmounted: ->
    @offers_modal.dispose()

  watch: {
    pagination: (v) -> console.log 'watch:pagination', v
  }

  methods: {
    on_page: (page) ->
      @pagination.page = page
      @fetch_sellables()

    fetch_sellables: ->
      @loading_sellables = true
      params = {}
      (params[k] = v if !!v) for k, v of @filter
      params.page = @pagination.page
      api.sellables.index(params)
      .then (response) =>
        @sellables  = response.sellables
        @pagination.page = response.pagination.page
        @pagination.pages = response.pagination.pages
        @pagination.count = response.pagination.count
      .catch (error) -> console.error error
      .finally =>
        @loading_sellables = false

    fetch_sellable: (id) ->
      @loading_sellable.push(id)
      api.sellables.show(id)
      .then (sellable) =>
        @sellable = sellable
        @replace_sellable(sellable)
      .catch (error) -> console.error error
      .finally =>
        @loading_sellables = false
        @loading_sellable = @loading_sellable.filter (i) => i != id

    replace_sellable: (sellable) ->
      index = @sellables.findIndex (s) => s.id == sellable.id
      @sellables[index] = sellable

    filter_sellables: ->
      @pagination.page = 1
      @fetch_sellables()

    toggle_sellable_published: (sellable) ->
      @loading_sellable.push(sellable.id)
      api.sellables.publish { id: sellable.id, published: sellable.published }
      .then (returned_sellable) =>
        sellable.published_offers = returned_sellable.published_offers
        sellable.published = returned_sellable.published
        sellable.visible_on_product_page = returned_sellable.visible_on_product_page
      .catch (error) =>
        sellable.published = !sellable.published
      .finally =>
        @loading_sellable = @loading_sellable.filter (i) => i != sellable.id

    toggle_offer_published: (offer) ->
      api.offers.update { id: offer.id, published: offer.published }
      .then (updated_offer) => offer = updated_offer
      .catch (error) -> console.error 'toggle_offer_published.catch', error

    show_sellable_offers: (sellable) ->
      @sellable = sellable
      @loading_offers = true
      @offers = []
      @offers_modal.show()
      @$refs['offers_modal'].addEventListener 'hidden.bs.modal', (event) =>
        @fetch_sellable(sellable.id)
      , { once: true }
      api.offers.fetch { sellable_id: sellable.id }
      .then (offers) =>
        @offers = offers
      .catch (error) ->
        @dismiss_offers()
        console.error error
      .finally =>
        @loading_offers = false

    dismiss_offers: ->
      @offers_modal.hide()
  }
}
