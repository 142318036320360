
































import auth from '../../services/auth'

export default {
  data: -> {
    valid: true
    login: @$route.query.email
    invite_id: @$route.query.invite_id
    password: ''
    password_rules:
      required: (value) => !!value || 'Required.'
      min: (v) => v.length >= 8 || 'Min 8 characters'
    confirm_password_rules:
      match: (value) =>
    confirm_password: ''
    show_password: false
    error: false
    message: ''
    loading: false
  },

  computed: {
    confirm_password_rule: ->
      @password == @confirm_password || "Password must match";
  }

  methods:
    sign_up: ->
      if @$refs.form.validate()
        @loading = true
        auth.sign_up { login: @login, password: @password, invite_id: @invite_id }, (response) =>
          if response.status == 'error'
            @loading = false
            @error = true
            @message = response.message
          else
            @$router.replace { path: '/login' }
}
