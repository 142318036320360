import auth from './auth'
import config from './base'

export default {
  build_filter_url: (params) ->
    url = new URL("#{config.api_endpoint()}/orders")
    search_params = new URLSearchParams()
    search_params.append(param, values) for param, values of params when String(values).length > 0
    url.toString() + '?' + search_params.toString()

  fetch_orders: (params, callback) ->
    url = @build_filter_url(params)
    request_options = @build_options()
    fetch url, request_options
    .then (response) -> response.json()
    .then (json) -> if json.status is 'ok' then callback(json) else throw json
    .catch (response) -> if response.status == 'auth_failed' then window.location = '/logout'

  fetch_order: (params, callback) ->
    url = new URL("#{config.api_endpoint()}/orders/#{params.id}")
    request_options = @build_options()
    fetch url, request_options
    .then (response) -> response.json()
    .then (json) -> if json.status is 'ok' then callback(json) else throw json
    .catch (response) -> if response.status == 'auth_failed' then window.location = '/logout'

  build_options: ->
    headers:
      'Content-Type': 'text/plain'
      'http-x-auth': auth_token()?.id
}

auth_token = ->
  auth.logged_in()