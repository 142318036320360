














import SupplierEditForm from './_edit_form'
import BsSupplierSelect from '../../bs_supplier_select'

export default {

  components: { SupplierEditForm, BsSupplierSelect }

  computed: {
    supplier: ->
      @$store.state.suppliers.current

    supplier_loading: ->
      @$store.state.suppliers.loading
  }

  methods: {
    on_supplier_select: (supplier) ->
      @$store.dispatch('suppliers/fetch_one', supplier.id)

  }
}
