# import auth_api from '../services/auth'
import config from './base'

export default {
  sign_in: (credentials, callback) ->
    service_sign_in credentials, (response) ->
      set_store(response.manager) if response.status == 'ok'
      callback?(response)

  sign_up: (credentials, callback) ->
    fetch "#{config.api_endpoint()}/sign_up", build_request_options(credentials)
    .then (response) -> response.json()
    .then (json) -> console.log '[services.auth].sign_up', json; callback? json
    .catch (response) -> console.log 'catch', response

  logout: -> remove_store()

  logged_in: -> get_store()

  on_change: -> logged_in()
}

build_request_options = (credentials) ->
  {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
    body: JSON.stringify({ credentials... })
  }

service_sign_in = (credentials, callback) ->
  fetch "#{config.api_endpoint()}/sign_in", build_request_options(credentials)
  .then (response) -> response.json()
  .then (json) -> callback? json
  .catch (error) -> throw error

get_store = -> JSON.parse localStorage.getItem('user')

set_store = (data) -> localStorage.setItem 'user', JSON.stringify(data)

remove_store = -> localStorage.removeItem 'user'
