import auth from './auth'
import application from '../application.yml'
import { merge } from 'lodash'

base_url = ->
  application.api_host

api_endpoint = ->
  "#{base_url()}/manager/api/v1"

auth_token = ->
  auth.logged_in()

download = (path, params) ->
  window.fetch build_url(path, params), {
    method: 'GET',
    headers: authenticated_json()
  }
  .then (response) ->
    response
  .catch (response) -> console.error '[api.download] response', response

build_url = (path, params={}) ->
  url = new URL("#{base_url()}/manager/api/v1#{path}")
  search_params = new URLSearchParams(params)
  if Object.keys(params).length > 0
    url.toString() + '?' + search_params.toString()
  else url.toString()

api_endpoint = -> new URL("#{base_url()}/manager/api/v1").toString()

authenticated_json = ->
  {
    'http-x-auth': auth_token()?.id,
    'Content-Type': 'application/json'
  }

build_options = ->
  {
    headers: {
      'Content-Type': 'text/plain'
      'http-x-auth': auth_token()?.id
    }
  }

fetch = (path, options) ->
  window.fetch(build_url(path, options), build_options())
  .then (response) ->
    return window.location = '/logout' if response.status == 401
    response.json()
  .then (data) -> data
  .catch (error) ->
    console.error 'base.fetch', error

update_supplier = (id, data) ->
  window.fetch build_url("/suppliers/#{id}"), {
    method: 'PATCH',
    headers: authenticated_json(),
    body: JSON.stringify(data),
  }
  .then (response) -> response.json()
  .then (data) -> data
  .catch (error) ->
    console.error 'base.update', error

# COMBOS
combo_create = (params, success, error) ->
  window.fetch build_url('/combos'), {
    method: 'POST',
    headers: authenticated_json(),
    body: JSON.stringify(params)
  }
  .then (response) -> response.json()
  .then (response) ->
    if response.status == 'ok' then response.sellable else throw response.messages

combos_index = ->
  window.fetch build_url('/combos'), {
    method: 'GET',
    headers: authenticated_json()
  }
  .then (response) -> response.json()
  .catch (error) ->
    console.error 'combos_index', error

combos = {
  show: (id) ->
    window.fetch build_url("/combos/#{id}/edit"), {
      method: 'GET',
      headers: authenticated_json()
    }
    .then (sellable) -> sellable.json()
    .catch (error) -> console.error('api.combo_show.catch', error)

  update: (sellable) ->
    window.fetch build_url("/combos/#{sellable.id}"), {
      method: 'PUT',
      headers: authenticated_json(),
      body: JSON.stringify({ sellable })
    }
    .then (response) -> response.json()
    .then (json) ->
      if json.status == 'ok' then json.sellable else throw json.messages

  add_item: ({ id, combo_item }) ->
    window.fetch build_url("/combos/#{id}/add_combo_item"), {
      method: 'POST',
      headers: authenticated_json()
      body: JSON.stringify({ combo_item })
    }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.combos.add_item', error

  remove_item: ({ id, combo_item }) ->
    window.fetch build_url("/combos/#{id}/remove_combo_item"), {
      method: 'DELETE',
      headers: authenticated_json()
      body: JSON.stringify({ combo_item })
    }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.combos.remove_item', error
}

sellables = {
  index: (params) ->
    query_params = new URLSearchParams(params).toString()
    window.fetch build_url("/sellables?#{query_params}"), {
      method: 'GET',
      headers: authenticated_json()
    }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.sellables.index.catch:', error

  search: (query, page=1) ->
    window.fetch build_url("/sellables/search?q=#{query}&page=#{page}"), {
      method: 'GET',
      headers: authenticated_json()
    }
    .then (response) -> response.json()
    .catch (error) -> console.error('api.sellables.fetch', error)

  publish: (sellable) ->
    action = if sellable.published then 'publish' else 'unpublish'
    window.fetch build_url("/sellables/#{sellable.id}/#{action}"), {
      method: 'GET',
      headers: authenticated_json()
    }
    .then (response) -> response.json()
    .then (json) ->
      if json.status == 'ok' then json.sellable else throw json.messages

  update_positions: (sellable_ids) ->
    window.fetch build_url('/sellables/update_positions'), {
      method: 'POST',
      headers: authenticated_json(),
      body: JSON.stringify({ sellables: sellable_ids })
    }
    .then (response) -> response.json()
    .catch (error) -> console.error('api.sellables.reorder', error)

  show: (id) ->
    window.fetch build_url("/sellables/#{id}"), {
      method: 'GET',
      headers: authenticated_json()
    }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.sellables.show.catch:', error
}

offers = {
  fetch: ({ sellable_id }) ->
    window.fetch build_url("/offers?sellable_id=#{sellable_id}"), {
      method: 'GET',
      headers: authenticated_json()
    }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.offers/fetch', error

  update: (offer) ->
    window.fetch build_url("/offers/#{offer.id}"), {
      method: 'PUT'
      headers: authenticated_json()
      redirect: 'error'
      body: JSON.stringify(offer)
    }
    .then (offer) -> offer.json()
    .catch (error) -> console.error 'api.offers.update', error
}

images = {
  search: (params) ->
    if params.next_page
      url = build_url('/images') + "?q=#{params.q}&page=#{params.next_page}&per_page=#{params.per_page}"
      window.fetch url, {
        method: 'GET'
        headers: authenticated_json(),
      }
      .then (response) -> response.json()
      .catch (error) -> console.error 'api.images.index:catch', error
}

leaflets = {
  index: (params) ->
    query_params = new URLSearchParams(params).toString()
    window.fetch build_url("/leaflets?#{query_params}"), {
      method: 'GET',
      headers: authenticated_json()
    }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.leaflets.index.catch:', error

  create: (params) ->
    window.fetch build_url("/leaflets"), {
      method: 'POST',
      headers: authenticated_json(),
      body: JSON.stringify(params)
    }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.leaflets.create.catch:', error

  update: (id, params) ->
    window.fetch build_url("/leaflets/#{id}"), {
      method: 'PUT',
      headers: authenticated_json(),
      body: JSON.stringify(params)
    }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.leaflets.update.catch:', error
}

payments = {
  index: (params) ->
    query_params = new URLSearchParams(params).toString()
    window.fetch build_url("/payments?#{query_params}"), {
      method: 'GET',
      headers: authenticated_json()
    }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.payments.index.catch:', error
}

discount_codes = {
  ui: ->
    window.fetch build_url("/discount_codes.html"), {
      method: 'GET',
      headers: authenticated_json()
    }
    .then (response) ->
      console.log 'ui.response', response
      console.log 'ui.response', response.body.read()
      URL.createObjectURL(response.body)
    .catch (error) -> console.error 'api.discount_codes.ui.catch:', error

  index: (params) ->
    query_params = new URLSearchParams(params).toString()
    window.fetch build_url("/discount_codes?#{query_params}"), {
      method: 'GET',
      headers: authenticated_json()
    }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.leaflets.index.catch:', error

  create: (params) ->
    window.fetch build_url("/discount_codes"), {
      method: 'POST',
      headers: authenticated_json(),
      body: JSON.stringify(params)
    }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.discount_codes.create.catch:', error

  update: (id, params) ->
    window.fetch build_url("/discount_codes/#{id}"), {
      method: 'PUT',
      headers: authenticated_json(),
      body: JSON.stringify(params)
    }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.discount_codes.update.catch:', error

  destroy: (id) ->
    window.fetch build_url("/discount_codes/#{id}"), { method: 'DELETE', headers: authenticated_json() }
    .then (response) -> response.json()
    .catch (error) -> console.error 'api.discount_codes.update.catch:', error
}

export default {
  url: base_url(),
  api_endpoint,
  authenticated_json,
  build_options,
  build_url,
  download,
  fetch,
  update_supplier,
  combo_create,
  combos_index,
  sellables,
  combos,
  offers,
  images,
  leaflets,
  payments,
  discount_codes
}
