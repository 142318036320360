














































































import api from 'manager/services/base'

export default {

  data: -> {
    form: { barcode: '', combo: true }
    form_errors: []
    combos: []
    loading: true
    sorting: false
  }

  methods: {
    create_combo: (event) ->
      event.preventDefault()
      @form_errors = []
      @loading = true
      api.combo_create({ sellable: JSON.parse(JSON.stringify(@form)) })
      .then (combo) => @create_combo_success(combo)
      .catch (errors) => @create_combo_error(errors)

    create_combo_success: (combo) ->
      @$router.push("/combos/#{combo.id}")

    create_combo_error: (errors) ->
      @loading = false
      @form_errors = errors

    on_combos_sort: (event) ->
      @sorting = true
      order = @combos.map((combo) -> combo.id)
      api.sellables.update_positions(order)
      .then (response) => @combos = response.sellables
      .catch (error) -> console.log 'on_combos_sort', error
      .finally => @sorting = false
  }

  created: ->
    @loading = true
    api.combos_index()
    .then (response) => @combos = response
    .catch (error) ->
      console.error 'api.combos_index', error
    .finally =>
      @loading = false
}
